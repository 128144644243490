<template>
	<div class="emb-register-wrap section-gap">
		<div class="container py-0">
			<v-layout row wrap align-center justify-center>
				<v-flex sm12 md12 lg8 xl7>
					<v-layout row mx-sm-0 mx-3  wrap align-center justify-center>
						<v-flex sm10 md5 lg6 xl6>
							<div class="emb-card sign-in-form form-margin d-block white pa-6 primary">
								<h3 class="text-center">Registrarse</h3>
								<v-form >
									<v-row dense no-gutters>
										<v-col cols="12" xl="12" lg="12" md="12" sm="12">
											<v-text-field
												type="text"
												v-model="form.name"
												required
												label="Nombre"
												autocomplete="off"
											>
											</v-text-field>
											<small class="error--text" v-if="errors.name" v-text="errors.name[0]"></small>
											<!-- <div class="form-group" :class="{'error--text': errors.name}">
												<label>
													Nombre
													<el-input
														v-model="form.name"
														type="text"
													>
													</el-input>
													<small class="error--text" v-if="errors.name" v-text="errors.name[0]"></small>
												</label>
											</div> -->
										</v-col>
										<v-col cols="12" xl="12" lg="12" md="12" sm="12">
											<v-text-field
												type="email"
												v-model="form.email"
												required
												label="Correo electrónico"
												autocomplete="off"
											>
											</v-text-field>
											<small class="error--text" v-if="errors.email" v-text="errors.email[0]"></small>
											<!-- <div class="form-group" :class="{'error--text': errors.email}">
												<label>
													Correo electrónico
													<el-input
														v-model="form.email"
														type="email"
													>
													</el-input>
													<small class="error--text" v-if="errors.email" v-text="errors.email[0]"></small>
												</label>
											</div> -->
										</v-col>
										<v-col cols="12" xl="12" lg="12" md="12" sm="12">
											<v-text-field
												type="text"
												v-model="form.phone"
												required
												label="Telefono"
												autocomplete="off"
											>
											</v-text-field>
											<small class="error--text" v-if="errors.phone" v-text="errors.phone[0]"></small>
											<!-- <div class="form-group" :class="{'error--text': errors.phone}">

												<label>
													Telefono
													<el-input
														v-model="form.phone"
														type="text"
													>
														<el-select v-model="form.country_code" slot="prepend" placeholder="Cód. de pais">
															<el-option v-for="(row, index) in country_codes" :key="index" :label="row.name" :value="row.value"></el-option>
														</el-select>
													</el-input>
													<small class="error--text" v-if="errors.phone" v-text="errors.phone[0]"></small>
												</label>
											</div> -->
										</v-col>
										<v-col cols="12" xl="12" lg="12" md="12" sm="12">
											<v-text-field
												label="Contraseña"
												:type="showPassword ? 'text' : 'password'"
												v-model="form.password"
												:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
												@click:append="showPassword = !showPassword"
											>
											</v-text-field>
											<small class="error--text" v-if="errors.password" v-text="errors.password[0]"></small>
											<!-- <div class="form-group" :class="{'error--text': errors.password}">
												<label>
													Contraseña
													<el-input
														v-model="form.password"
														type="password"
														show-password
													>
													</el-input>
													<small class="error--text" v-if="errors.password" v-text="errors.password[0]"></small>
												</label>
											</div> -->
										</v-col>
										<v-col cols="12" xl="12" lg="12" md="12" sm="12">
											<v-text-field
												label="Confirmar contraseña"
												:type="showPasswordConfirm ? 'text' : 'password'"
												v-model="form.password_confirmation"
												:append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
												@click:append="showPasswordConfirm = !showPasswordConfirm"
											>
											</v-text-field>
											<small class="error--text" v-if="errors.password_confirmation" v-text="errors.password_confirmation[0]"></small>

											<!-- <div class="form-group">
												<label>
													Confirmar contraseña
													<el-input
														v-model="form.password_confirmation"
														type="password"
														show-password
													>
													</el-input>
												</label>
											</div> -->
										</v-col>
										<!-- <v-col cols="12" xl="12" lg="12" md="12" sm="12">
											<div class="form-group" :class="{'error--text': errors.via}">
												<label>
													Verificar mis datos mediante:
													<el-radio-group v-model="form.via" class="pt-4">
														<el-radio label="sms">Mensaje de texto</el-radio>
														<el-radio label="email">Correo electrónico</el-radio>
													</el-radio-group>
													<small class="error--text" v-if="errors.via" v-text="errors.via[0]"></small>
												</label>
											</div>
										</v-col> -->
									</v-row>
									
									<v-btn class="mx-0 mb-2" color="primary" block @click.stop.prevent="register" :loading="loading" :disabled="loading">
										Crear cuenta
										<!-- <template v-slot:loader>
											<span>Creando...</span>
										</template> -->
									</v-btn>
									<p class="text-center">¿Ya tiene una cuenta? <router-link :to="{name: 'login'}" class="accent--text"> Iniciar sesión</router-link></p>
								</v-form>
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</div>
   </div>
</template>


<script>

	import {mapActions} from 'vuex';
	export default {
		data () {
			return {
				form: {},
				errors: {},
				loading: false,
				showPassword: false,
				showPasswordConfirm: false,
				country_codes: [
					{value: '+51', name: '+51 (Perú)'}
				]
			}
		},
		created() {
			this.initForm()
		},
		methods: {
			...mapActions({
                addNotification: 'addNotification'
			}),
			initForm() {
				this.form = {
					id: null,
					type: 'user',
					name: null,
					email: null,
					country_code: '+51',
					phone: null,
					via: 'email',
                    password: null,
                    password_confirmation: null
				}
			},
			async register() {

				this.errors = {}
					
				this.loading = true
				await this.$store.dispatch('register', this.form).then(response => {
					if (response.data.success) {
						this.initForm()

						this.addNotification({
							show: true,
							type: 'success',
							message: response.data.message
						})

						this.$router.push({name: 'payment'});
					} else {
						this.addNotification({
							show: true,
							type: 'error',
							message: response.data.message
						})
					}

				}).catch(error => {
					if (error.response.status === 422) {
						this.errors = error.response.data.message
					} else {
						console.log(error)
					}
					this.loading = false
				}).then(() => {
					this.loading = false
				});

			}
		}
	}
</script>

<style>
	/* .el-select .el-input {
		width: 150px;
	}
	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	} */
</style>